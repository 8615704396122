import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableContainer,
  TableCell,
  Paper,
} from "@mui/material";
import { tableCellClasses } from "@mui/material/TableCell";
import TransformDate from "../../utils/TransformDate";
import { BlobProvider } from "@react-pdf/renderer";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import LoopIcon from "@mui/icons-material/Loop";
import ClientInfoModal from "../client/ClientInfoModal";
import {
  PrimaryColor,
  TableBodyGrey,
  TableHeaderGrey,
} from "../../shared/styles/colors";
import DatabaseEntities from "../../model/enum/DatabaseEntities";
import { Card, CardContent, Typography, Button } from "@material-ui/core";
import { TablePagination } from "@mui/material";
import { Spinner } from "../../components";
import { ToastContainer } from "react-toastify";
import ApiService from "../../service/ApiService";
import AlertService from "../../service/AlertService";
import ToastMessages from "../../model/enum/ToastMessages";
import EstimateModel from "../../model/estimate";
import CachedIcon from "@mui/icons-material/Cached";
import useStyles from "../styles/cardStyles";
import EstimateModal from "./EstimateModal";
import Status from "../../model/enum/StatusClass";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EstimateDetailModal from "./EstimateDetailModal";
import EstimatePDFView from "./EstimateView";

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: TableHeaderGrey,
    color: PrimaryColor,
    fontWeight: 700,
    whiteSpace: "nowrap",
    zIndex: 0,
    position: "static",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    color: TableBodyGrey,
    fontFamily: "monospace",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const Estimates = () => {
  const classes = useStyles();
  const [estimates, setEstimates] = useState<EstimateModel[]>([]);
  const [items, setItems] = useState<EstimateModel[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [toggleSpinner, setToggleSpinner] = useState(true);

  // Get all the estimates from the database
  useEffect(() => {
    const fetchData = async () => {
      await ApiService.get<EstimateModel[]>(DatabaseEntities.ESTIMATES).then(
        (data) => {
          if (data.length == 0) setToggleSpinner(false);

          setEstimates(data);
          for (let i = 0; i < rowsPerPage && i < data.length; i++) {
            items.push(data[i]);
          }
          setToggleSpinner(false);
        }
      );
    };

    if (toggleSpinner && estimates.length <= 0) {
      fetchData();
    }
  }, [estimates]);

  const handleCreate = async (
    payload: any,
    notify: boolean = true
  ): Promise<EstimateModel | undefined> => {
    try {
      const res = await ApiService.post<EstimateModel>(
        DatabaseEntities.ESTIMATES,
        payload
      );
      if (notify) AlertService.succes(ToastMessages.CREATED);
      resetList();
      return res;
    } catch {
      AlertService.error(ToastMessages.CREATED_FAILED);
      return undefined;
    }
  };

  const handleUpdate = async (payload: any, notify: boolean = true) => {
    await ApiService.put<EstimateModel>(
      `${DatabaseEntities.ESTIMATES}/${payload.id}`,
      payload
    )
      .then(() => {
        setToggleSpinner(true);
        if (notify) AlertService.succes(ToastMessages.UPDATED);
        resetList();
      })
      .catch(() => {
        AlertService.error(ToastMessages.UPDATE_FAILED);
      });
  };

  const handleDelete = async (payload: any, notify: boolean = true) => {
    await ApiService.delete<any>(`${DatabaseEntities.ESTIMATES}/${payload.id}`)
      .then(() => {
        setToggleSpinner(true);
        if (notify) AlertService.succes(ToastMessages.DELETED);
        resetList();
      })
      .catch(() => {
        AlertService.error(ToastMessages.DELETE_FAILED);
      });
  };

  const resetList = () => {
    setEstimates([]);
    setItems([]);
    setPage(0);
    setToggleSpinner(true);
  };

  const handlePageChange = (
    event: React.ChangeEvent<unknown> | null,
    pg: number,
    itemsPerPage: number = rowsPerPage
  ) => {
    setToggleSpinner(true);
    setPage(pg);
    let MaxElem = pg * itemsPerPage;

    items.forEach((_itm: EstimateModel, index) => {
      items.splice(index);
    });

    for (let i = MaxElem; i < MaxElem + itemsPerPage; i++) {
      if (i < estimates.length) items.push(estimates[i]);
    }

    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    setToggleSpinner(false);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    let pg = parseInt(event.target.value);
    setRowsPerPage((rowsPerPage) => pg);
    setPage((page) => 0);
    handlePageChange(null, 0, pg);
  };

  return (
    <>
      <Card raised className={classes.card}>
        <div className={classes.headerContainer}>
          <Typography className={classes.title}>Estimates</Typography>
          <div className={classes.headerDiv}>
            <EstimateModal handleCreate={handleCreate} />
            <Button onClick={resetList}>
              <CachedIcon />
            </Button>
          </div>
        </div>
        <CardContent>
          <Paper>
            <TableContainer>
              <Table
                stickyHeader
                aria-label="customized table"
                padding="normal"
                size="medium"
              >
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="right">Client</StyledTableCell>
                    <StyledTableCell align="right">Code</StyledTableCell>
                    <StyledTableCell align="right">Total Value</StyledTableCell>
                    <StyledTableCell align="right">
                      Starting Date
                    </StyledTableCell>
                    <StyledTableCell align="right">Due Date</StyledTableCell>
                    <StyledTableCell align="right">Status</StyledTableCell>
                    <StyledTableCell align="right">Action</StyledTableCell>
                    <StyledTableCell align="right">
                      <MoreVertIcon />
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                {toggleSpinner ? (
                  <Spinner />
                ) : (
                  <TableBody>
                    {items.length > 0 ? (
                      items.map((row: EstimateModel) => (
                        <StyledTableRow key={row.id}>
                          <StyledTableCell align="right">
                            <ClientInfoModal clientData={row.Client} />
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            #{row.code}
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            ${row.total},00
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            {TransformDate.toFullDate(row.startsAt)}
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            {TransformDate.toFullDate(row.dueDate)}
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            <Typography
                              style={Status.mapStatusColor(row.Status?.name)}
                            >
                              <div
                                style={Status.mapStatusRoundedColor(
                                  row.Status?.name
                                )}
                              ></div>
                              {row.Status?.name}
                            </Typography>
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            <BlobProvider document={<EstimatePDFView estimate={row} />}>
                              {({ url }: any) => {
                                const filename = `${row.code}_${TransformDate.toFullDate(row.createdAt)}.pdf`;
                                return (
                                  <a
                                    href={url}
                                    target="_blank"
                                    rel="noreferrer"
                                    //download={filename}
                                  >
                                    <Button>
                                      {url ? (
                                        <PictureAsPdfIcon />
                                      ) : (
                                        <LoopIcon />
                                      )}
                                    </Button>
                                  </a>
                                );
                              }}
                            </BlobProvider>
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            <EstimateDetailModal
                              estimate={row}
                              handleUpdate={handleUpdate}
                              handleDelete={handleDelete}
                            />
                          </StyledTableCell>
                        </StyledTableRow>
                      ))
                    ) : (
                      <StyledTableCell align="right">No items</StyledTableCell>
                    )}
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Paper>
          <TablePagination
            component="div"
            count={estimates.length}
            page={page}
            onPageChange={handlePageChange}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </CardContent>
      </Card>
      <ToastContainer />
    </>
  );
};

export default Estimates;
