import React, { useState, useEffect } from "react";
import { Button, Box, Modal } from "@mui/material";
import { Button as Btn, Typography } from "@material-ui/core";
import { NumberFormatter } from "react-number-formatter";
import SendEmailModal from "../modal/SendEmailModal";
import ClientModel from "../../model/client";
import CloseIcon from "@mui/icons-material/Close";
import ZillowIcon from "../../assets/admin/zillow-icon.svg";
import ApiService from "../../service/ApiService";
import MapIcon from "../../assets/admin/google-maps-icon.svg";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import useStyles from "../modal/styles";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "45rem",
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "8px",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  maxHeight: "90vh", // Restrict maximum height to 80% of the viewport height
  overflowY: "auto", // Enable vertical scrolling
  "@media (max-width: 640px)": {
    width: "80%",
  },
};

const ClientInfoModal = ({ clientData }: any) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [client, setClient] = useState<ClientModel>();
  const [toggleMore, setToggleMore] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      await ApiService.get<ClientModel>(`clients/${clientData.id}`).then(
        (data) => {
          setClient(data);
        }
      );
    };

    if (open && !client && clientData) fetchData();
  });

  return (
    <>
      <div>
        <Button onClick={handleOpen}>
          {clientData.firstName} {clientData.lastName}
        </Button>
        {client && (
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{ overflowY: "auto" }}
          >
            <Box sx={style}>
              <div className={classes.headerDiv}>
                <Typography className={classes.title}>Client info</Typography>
                <div className={classes.headerSubDiv}>
                  {client?.street && (
                    <Button>
                      <a
                        href={`http://maps.google.com/?q=${client.street}, ${client.city}, ${client.state} ${client.zip}`}
                        target="_blank"
                      >
                        <img
                          src={MapIcon}
                          alt="google map icon"
                          style={{ width: "15px" }}
                        />
                      </a>
                    </Button>
                  )}
                  <Button onClick={handleClose} sx={{ color: "black" }}>
                    <CloseIcon />
                  </Button>
                </div>
              </div>
              <hr />
              <div className={classes.form}>
                <div className={classes.groupedItems}>
                  <Typography className={classes.label}>Id</Typography>
                  <input className={classes.input} value={client.id} disabled />
                </div>
                <div className={classes.groupedItems}>
                  <Typography className={classes.label}>Name</Typography>
                  <input
                    className={classes.input}
                    value={client.firstName + " " + client.lastName}
                    disabled
                  />
                </div>
                <div className={classes.inputGroup}>
                  <div className={classes.groupedItems}>
                    <Typography className={classes.label}>Email</Typography>
                    <input
                      className={classes.input}
                      value={client.email}
                      disabled
                    />
                  </div>
                  <div className={classes.groupedItems}>
                    <Typography className={classes.label}>Phone</Typography>
                    <div className={classes.input}>
                      <a href={"tel:+" + client.phone} className="ui-link">
                        <NumberFormatter
                          defaultCountry="USA"
                          value={client.phone}
                          disabled
                        />
                      </a>
                    </div>
                  </div>
                </div>
                <div className={classes.inputGroup}>
                  <div className={classes.groupedItems}>
                    <Typography className={classes.label}>Street</Typography>
                    <input
                      className={classes.input}
                      value={client?.street}
                      disabled
                    />
                  </div>
                  <div className={classes.groupedItems}>
                    <Typography className={classes.label}>Zip code</Typography>
                    <input
                      className={classes.input}
                      value={client?.zip}
                      disabled
                    />
                  </div>
                </div>
                <div className={classes.inputGroup}>
                  <div className={classes.groupedItems}>
                    <Typography className={classes.label}>City</Typography>
                    <input
                      className={classes.input}
                      value={client?.city}
                      disabled
                    />
                  </div>
                  <div className={classes.groupedItems}>
                    <Typography className={classes.label}>State</Typography>
                    <input
                      className={classes.input}
                      value={client?.state}
                      disabled
                    />
                  </div>
                </div>
                <div className={classes.inputGroup}>
                  <div className={classes.groupedItems}>
                    <Typography className={classes.label}>
                      Client Type
                    </Typography>
                    <input
                      className={classes.input}
                      value={client?.ClientType?.name}
                      disabled
                    />
                  </div>
                  <div className={classes.groupedItems}>
                    <Typography className={classes.label}>
                      Client Level
                    </Typography>
                    <input
                      className={classes.input}
                      value={client?.ClientLevel?.name}
                      disabled
                    />
                  </div>
                </div>
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Button onClick={() => setToggleMore(!toggleMore)}>
                  {toggleMore ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </Button>
              </div>
              <div
                style={{
                  transition: "max-height 0.9s ease-in-out",
                  overflow: "hidden",
                  maxHeight: toggleMore ? "1000px" : "0",
                }}
              >
                {toggleMore && (
                  <>
                    <div className={classes.inputGroup}>
                      <div className={classes.groupedItems}>
                        <Typography className={classes.label}>
                          Alternative Name
                        </Typography>
                        <input
                          className={classes.input}
                          value={client.alternativeName}
                          disabled
                        />
                      </div>
                      <div className={classes.groupedItems}>
                        <Typography className={classes.label}>
                          Alternative Phone
                        </Typography>
                        <input
                          className={classes.input}
                          value={client?.alternativePhone}
                          disabled
                        />
                      </div>
                    </div>
                    <div className={classes.groupedItems}>
                      <Typography className={classes.label}>Notes</Typography>
                      <textarea
                        className={classes.input}
                        value={client?.notes}
                        disabled
                      />
                    </div>
                  </>
                )}
              </div>
              <div className={classes.buttonGroup}>
                <SendEmailModal
                  name={client?.firstName}
                  email={client?.email}
                />
                {client?.street && (
                  <Btn
                    className={classes.button}
                    onClick={() =>
                      window.open(
                        `https://www.zillow.com/homes/${client.street}, ${client.city}, ${client.state} ${client.zip}`
                      )
                    }
                  >
                    <img src={ZillowIcon} alt="zillow icon" />
                    <Typography style={{ marginLeft: "5%" }}>Zillow</Typography>
                  </Btn>
                )}
              </div>
            </Box>
          </Modal>
        )}
      </div>
    </>
  );
};

export default ClientInfoModal;
