import React, { useEffect, useState } from "react";
import { Box, Button, Modal } from "@mui/material";
import { Button as Btn, Typography } from "@material-ui/core";
import { NumberFormatter } from "react-number-formatter";
import ClientModel from "../../model/client";
import US_States from "../../model/enum/US_States";
import ClientServices from "../../model/enum/ClientServices";
import DeleteModal from "../modal/DeleteModal";
import EditIcon from "../../assets/admin/edit-icon.svg";
import CloseIcon from "@mui/icons-material/Close";
import ApiService from "../../service/ApiService";
import DatabaseEntities from "../../model/enum/DatabaseEntities";
import AlertService from "../../service/AlertService";
import ToastMessages from "../../model/enum/ToastMessages";
import ClientLevel from "../../model/clientLevel";
import ClientType from "../../model/clientType";
import useStyles from "./styles";
import { DarkGrey } from "../../shared/styles/colors";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40rem",
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "8px",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  maxHeight: "90vh", // Restrict maximum height to 80% of the viewport height
  overflowY: "auto", // Enable vertical scrolling
  "@media (max-width: 640px)": {
    width: "80%",
  },
};

const ClientModal = ({
  client,
  externalService,
  resetList,
  setClient,
  handleUpdate,
  handleCreate,
  handleDelete,
}: any) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const edit = client != null;
  const id = edit ? client.id : 0;
  const [firstName, setFirstName] = useState(edit ? client.firstName : null);
  const [lastName, setLastName] = useState(edit ? client.lastName : null);
  const [email, setEmail] = useState(edit ? client.email : null);
  const [phone, setPhone] = useState(edit ? client.phone : null);
  const [zipCode, setZipCode] = useState(
    edit && client.zip ? client?.zip : null
  );
  const [street, setStreet] = useState(
    edit && client.street ? client.street : null
  );
  const [city, setCity] = useState(edit && client.city ? client.city : null);
  const [state, setState] = useState(
    edit && client.state ? client.state : null
  );
  const [level, setLevel] = useState(edit ? client.ClientLevel : null);
  const [levels, setLevels] = useState<ClientLevel[]>([]);
  const [type, setType] = useState(edit ? client.ClientType : null);
  const [types, setTypes] = useState<ClientType[]>([]);
  const [altName, setAltName] = useState(
    edit && client.alternativeName != "" ? client.alternativeName : null
  );
  const [altPhone, setAltPhone] = useState(
    edit && client.alternativePhone != "" ? client.alternativePhone : null
  );
  const [notes, setNotes] = useState(edit ? client.notes : null);
  const [toggleSpinner, setToggleSpinner] = useState(true);

  const US_State = new US_States();

  useEffect(() => {
    const fetchData = async () => {
      setToggleSpinner(true);

      await ApiService.get<ClientLevel[]>(
        `${DatabaseEntities.CLIENTS}/${DatabaseEntities.CLIENT_LEVELS}`
      ).then((data: ClientLevel[]) => {
        setLevels(data);
      });

      setToggleSpinner(false);
    };

    if (levels.length <= 0 && open) fetchData();
  });

  useEffect(() => {
    const fetchData = async () => {
      setToggleSpinner(true);

      await ApiService.get<ClientType[]>(
        `${DatabaseEntities.CLIENTS}/${DatabaseEntities.CLIENT_TYPES}`
      ).then((data: ClientType[]) => {
        setTypes(data);
      });

      setToggleSpinner(false);
    };

    if (types.length <= 0 && open) fetchData();
  });

  const handleFirstName = (e: any) => {
    setFirstName(e.target.value);
  };

  const handleLastName = (e: any) => {
    setLastName(e.target.value);
  };

  const handleEmail = (e: any) => {
    e.preventDefault();
    setEmail(e.target.value);
  };

  const handlePhone = (e: any) => {
    setPhone(e);
  };

  const handleZipCode = (e: any) => {
    e.preventDefault();
    let zip = e.target.value;
    if (!isNaN(zip)) {
      if (US_State.isZipValid(zip)) {
        setCity(US_State.getStateCity(zip));
        setState(US_State.getStateName(zip));
      } else {
        setCity("");
        setState("");
      }
      setZipCode(zip);
    }
  };

  const handleStreet = (e: any) => {
    e.preventDefault();
    setStreet(e.target.value);
  };

  const handleCity = (e: any) => {
    e.preventDefault();
    setCity(e.target.value);
  };

  const handleState = (e: any) => {
    e.preventDefault();
    setState(e.target.value);
  };

  const handleAltName = (e: any) => {
    setAltName(e.target.value);
  };

  const handleAltPhone = (e: any) => {
    setAltPhone(e);
  };

  const handleNotes = (e: any) => {
    setNotes(e.target.value);
  };

  const handleLevel = (e: any) => {
    let value = levels.find((lvl: ClientLevel) => lvl.id == e.target.value);
    setLevel(value);
  };

  const handleType = (e: any) => {
    let value = types.find((tp: ClientType) => tp.id == e.target.value);
    setType(value);
  };

  const register = async () => {
    if (invalidInput()) return;

    const payload: ClientModel = {
      id: id,
      firstName: firstName,
      lastName: lastName,
      email: email,
      phone: phone,
      street: street,
      zip: zipCode,
      city: city,
      state: state,
      alternativeName: altName,
      alternativePhone: altPhone,
      clientLevelId: level ? level.id : null,
      clientTypeId: type ? type.id : null,
      notes: notes,
      service: ClientServices.MANUAL,
    };

    if (!externalService) {
      if (edit) await handleUpdate(payload);
      else await handleCreate(payload);
    } else {
      await ApiService.post<ClientModel>(DatabaseEntities.CLIENTS, payload)
        .then((res) => {
          AlertService.succes(ToastMessages.CREATED);
          setClient(res);
          resetList();
        })
        .catch((error) => {
          AlertService.error(error);
        });
    }

    handleClose();
  };

  const invalidInput = () => {
    return (
      firstName === "" ||
      email === "" ||
      email?.indexOf("@") === -1 ||
      email?.indexOf(".") === -1 ||
      phone === ""
    );
  };

  return (
    <div>
      {edit ? (
        <Button onClick={handleOpen}>
          <img src={EditIcon} alt="edit icon" />
        </Button>
      ) : !externalService ? (
        <Btn
          className={classes.headerButton}
          variant="contained"
          onClick={handleOpen}
        >
          + Add Client
        </Btn>
      ) : (
        <Btn className={classes.externalButton} onClick={handleOpen}>
          Add New
        </Btn>
      )}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ overflowY: "auto" }}
      >
        <Box sx={style}>
          <div className={classes.headerDiv}>
            {edit ? (
              <DeleteModal item={client} handleDelete={handleDelete} />
            ) : (
              <Typography className={classes.title}>Add client</Typography>
            )}
            <Button onClick={handleClose} sx={{ color: "black" }}>
              <CloseIcon />
            </Button>
          </div>
          <hr />
          <form className={classes.form}>
            <div className={classes.inputGroup}>
              <input
                className={classes.input}
                value={firstName}
                onChange={handleFirstName}
                placeholder="First Name"
              />
              <input
                className={classes.input}
                value={lastName}
                onChange={handleLastName}
                placeholder="Last Name"
              />
            </div>
            <div className={classes.inputGroup}>
              <input
                className={classes.input}
                value={email}
                type="email"
                onChange={handleEmail}
                placeholder="Email"
              />
            </div>
            <div className={classes.inputGroup}>
              <div className={classes.input}>
                <NumberFormatter
                  defaultCountry="USA"
                  placeholder="Phone"
                  value={phone}
                  getValue={(n: string) => handlePhone(n)}
                />
              </div>
            </div>
            <div className={classes.inputGroup}>
              <input
                className={classes.input}
                value={street}
                onChange={handleStreet}
                placeholder="Street"
              />
              <input
                className={classes.input}
                value={zipCode}
                onChange={handleZipCode}
                placeholder="ZIP code"
              />
            </div>
            <div className={classes.inputGroup}>
              <input
                className={classes.input}
                value={city}
                onChange={handleCity}
                placeholder="City"
              />
              <input
                className={classes.input}
                value={state}
                onChange={handleState}
                placeholder="State"
              />
            </div>
            <div className={classes.inputGroup}>
              <select
                className={classes.input}
                onChange={handleType}
                value={type?.id}
              >
                <option value="">Select type</option>
                {types.map((tp: ClientType) => {
                  return (
                    <option key={tp.id} value={tp.id}>
                      {tp.name}
                    </option>
                  );
                })}
              </select>
              <select
                className={classes.input}
                onChange={handleLevel}
                value={level?.id}
              >
                <option value="" style={{ color: DarkGrey }}>
                  Select level
                </option>
                {levels.map((lvl: ClientLevel) => {
                  return (
                    <option key={lvl.id} value={lvl.id}>
                      {lvl.name}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className={classes.inputGroup}>
              <input
                className={classes.input}
                value={altName}
                onChange={handleAltName}
                placeholder="Alternative Name"
              />
              <div className={classes.input}>
                <NumberFormatter
                  defaultCountry="USA"
                  placeholder="Alternative Phone"
                  value={altPhone}
                  getValue={(n: string) => handleAltPhone(n)}
                />
              </div>
            </div>
            <div className={classes.inputGroup}>
              <textarea
                className={classes.textarea}
                placeholder="Add some notes..."
                onChange={handleNotes}
                value={notes}
              />
            </div>
          </form>
          <div className={classes.buttonDiv}>
            <Btn
              className={classes.button}
              onClick={() => register()}
              disabled={invalidInput()}
            >
              Save
            </Btn>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default ClientModal;
