import { makeStyles } from "@material-ui/core/styles";
import {
  LightPrimaryColor,
  LightGrey,
  PrimaryColor,
  White,
  DarkGrey,
} from "../../styles/colors";

export default makeStyles(() => ({
  appBar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: White,
    width: "100% !important",
    height: "7rem",
    "@media (max-width: 640px)": {
      height: "5rem",
    },
  },
  logoDiv: {
    padding: "0.7%",
    marginLeft: "10%",
    width: "10%",
    "@media (max-width: 640px)": {
      padding: "0%",
      width: "50%",
      marginLeft: "0%",
    },
    "@media (max-width: 820px)": {
      width: "30%",
      marginLeft: "0%",
    },
  },
  logo: {
    width: "80%",
    "@media (max-width: 640px)": {
      width: "100%",
    },
  },
  textDiv: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
    width: "40%",
    marginLeft: "10%",
    "@media (max-width: 640px)": {
      display: "none",
    },
    "@media (max-width: 820px)": {
      width: "50%",
      marginLeft: "0%",
    },
  },
  text: {
    fontSize: "1rem",
    color: DarkGrey,
    "&:hover": {
      color: LightPrimaryColor,
    },
    "@media (max-width: 640px)": {
      fontSize: "1rem",
    },
    "@media (max-width: 820px)": {
      fontSize: ".8rem",
    },
  },
  textClicked: {
    fontSize: "1rem",
    color: PrimaryColor,
    fontWeight: "bold",
    "@media (max-width: 640px)": {
      fontSize: "1rem",
    },
    "@media (max-width: 820px)": {
      fontSize: ".8rem",
    },
  },
  menuIcon: {
    display: "block",
    flexDirection: "column",
    position: "absolute",
    marginLeft: "80%",
    color: PrimaryColor,
    "@media (min-width: 640px)": {
      display: "none",
    },
  },
  dropdown: {
    display: "flex",
    flexDirection: "column",
    position: "absolute",
    marginTop: "43%",
    width: "100%",
    borderRadius: "0.5rem",
    boxShadow: "0px 3px 10px 0px rgba(0,0,0,0.75)",
    background: White,
    left: 0,
  },
  appointment_button: {
    position: "absolute",
    marginLeft: "60%",
    maxWidth: "12rem",
    height: "2.4rem",
    borderRadius: ".3rem",
    border: "none",
    background: PrimaryColor,
    color: White,
    cursor: "pointer",
    zIndex: "1",
    fontSize: ".8rem",
    "&:hover": {
      background: LightPrimaryColor,
      color: White,
    },
    "@media (max-width: 640px)": {
      marginTop: "0%",
      marginLeft: "10%",
      width: "1rem",
      fontSize: ".8rem",
    },
    "@media (max-width: 820px)": {
      width: "8rem",
      fontSize: ".8rem",
    },
  },
  appointment_buttonClicked: {
    position: "absolute",
    marginLeft: "60%",
    maxWidth: "12rem",
    height: "2.4rem",
    borderRadius: ".3rem",
    border: "none",
    background: LightPrimaryColor,
    color: LightGrey,
    cursor: "disabled",
    zIndex: "1",
    fontSize: ".8rem",
    "@media (max-width: 640px)": {
      marginTop: "0%",
      marginLeft: "10%",
      overflowY: "auto",
      width: "5rem",
      fontSize: ".8rem",
    },
    "@media (max-width: 820px)": {
      width: "8rem",
      fontSize: ".8rem",
    },
  },
  //phone icon
  phone_button: {
    display: "flex",
    position: "fixed ",
    alignItems: "center",
    justifyContent: "center",
    bottom: 65,
    right: 16,
    width: "40px",
    height: "40px",
    border: 0,
    borderRadius: "20px",
    background: PrimaryColor,
    "&:hover": {
      background: LightPrimaryColor,
    },
  },
  phone_img: {
    width: "20px",
  },
  //number text
  phone_text: {
    fontSize: "1.2rem",
    fontWeight: 600,
    color: PrimaryColor,
    "&:hover": {
      color: LightPrimaryColor,
    },
    "@media (max-width: 640px)": {
      fontSize: ".8rem",
    },
  },
  divPhone: {
    position: "absolute",
    width: "13rem",
    fontSize: "24.60px",
    fontFamily: "Inter",
    fontWeight: 700,
    textTransform: "uppercase",
    wordWrap: "break-word",
    background: White,
    color: PrimaryColor,
    zIndex: 11,
    right: 100,
    top: 120,
    borderRadius: "180px 0px 180px 0px",
    "@media (max-width: 820px)": {
      top: 100,
      right: "25%",
      width: "10rem",
    },
  },
}));
