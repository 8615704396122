import React, { useState } from "react";
import { Box, Button, Modal } from "@mui/material";
import { NumberFormatter } from "react-number-formatter";
import { Typography } from "@material-ui/core";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CloseIcon from "@mui/icons-material/Close";
import UserModal from "./UserModal";
import UserModel from "../../model/user";
import RoleModel from "../../model/role";
import useStyles from "./styles";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "35rem",
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "8px",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  "@media (max-width: 640px)": {
    width: "80%",
    //top: "60%"
  },
};

interface Props {
  user: UserModel,
  roles: RoleModel[],
  handleCreate: any,
  handleUpdate: any,
};

const UserDetailModal = ({
  user,
  roles,
  handleCreate,
  handleUpdate,
}: Props) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Button onClick={handleOpen}>
        <MoreVertIcon />
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ overflowY: "auto" }}
      >
        <Box sx={style}>
          <div className={classes.headerDiv}>
            <Typography className={classes.title}>User info</Typography>
            <div className={classes.headerSubDiv}>
              <UserModal
                user={user}
                roles={roles}
                handleCreate={handleCreate}
                handleUpdate={handleUpdate}
              />
              <Button onClick={handleClose} sx={{ color: "black" }}>
                <CloseIcon />
              </Button>
            </div>
          </div>
          <hr />
          <form className={classes.form}>
            <div className={classes.inputDiv}>
              <Typography className={classes.label}>Name</Typography>
              <input
                className={classes.inputDetail}
                value={user.firstName + " " + user.lastName}
                disabled
              />
            </div>
            <div className={classes.inputGroup}>
              <div className={classes.inputDiv}>
                <Typography className={classes.label}>Email</Typography>
                <input
                  className={classes.inputDetail}
                  value={user.email}
                  type="email"
                  disabled
                />
              </div>
              <div className={classes.inputDiv}>
                <Typography className={classes.label}>Phone</Typography>
                <div className={classes.inputDetail}>
                  <NumberFormatter
                    defaultCountry="USA"
                    placeholder="Phone"
                    value={user.phone}
                    disabled
                  />
                </div>
              </div>
            </div>
            <div className={classes.inputGroup}>
              <div className={classes.inputDiv}>
                <Typography className={classes.label}>
                  Street address
                </Typography>
                <input
                  className={classes.inputDetail}
                  value={user.street}
                  disabled
                />
              </div>
              <div className={classes.inputDiv}>
                <Typography className={classes.label}>Zip code</Typography>
                <input
                  className={classes.inputDetail}
                  value={user.zip}
                  disabled
                />
              </div>
            </div>
            <div className={classes.inputGroup}>
              <div className={classes.inputDiv}>
                <Typography className={classes.label}>City</Typography>
                <input
                  className={classes.inputDetail}
                  value={user.city}
                  disabled
                />
              </div>
              <div className={classes.inputDiv}>
                <Typography className={classes.label}>State</Typography>
                <input
                  className={classes.inputDetail}
                  value={user.state}
                  disabled
                />
              </div>
            </div>
            <div className={classes.inputGroup}>
              {/* <div className={classes.inputDiv}>
                <Typography className={classes.label}>Job Title</Typography>
                <input
                  className={classes.inputDetail}
                  value={jobTitleName}
                  disabled
                />
              </div> */}
              <div className={classes.inputDiv}>
                <Typography className={classes.label}>Role</Typography>
                <input
                  className={classes.inputDetail}
                  value={user.Role?.name}
                  disabled
                />
              </div>
            </div>
          </form>
        </Box>
      </Modal>
    </div>
  );
};

export default UserDetailModal;
